define(['app'], (app) => {

  const wishlistProduct = () => {
    const component = {};
    const DATA_ITEM_REMOVE_ENABLED = 'data-item-remove-enabled';

    const _config = {
      productIds: {
        masterProductId: 'data-master-id',
        childProductId: 'data-child-id'
      },
      subscribeChannels: {
        update: 'wishlistProduct/update',
        cancelChanges: 'wishlistProduct/cancelChanges'
      },
      buttons: {
        basketButton: '.wishlistProduct_productAddToBasket',
      }
    };

    const _init = (element) => {
      component.element = element;
      component.masterProductId = component.element.getAttribute(component.config.productIds.masterProductId);
      component.childProductId = component.element.getAttribute(component.config.productIds.childProductId);
      component.basketButton = component.element.querySelector(component.config.buttons.basketButton);
      component.disabledButton = component.element.querySelector('.disabledButtonWrapper');
      component.itemRemoveEnabled = component.element.getAttribute(DATA_ITEM_REMOVE_ENABLED);

      component.basketButton.addEventListener('click', component.removeItemFromWishlist);
      return component;
    };

    const _removeItemFromWishlist = () => {
      if (component.itemRemoveEnabled === 'true') {

        let productId = null;
        if (component.childProductId !== '') {
          productId = component.childProductId;
        } else {
          productId = component.masterProductId;
        }

        return new Promise((res, rej) => app.ajax.call({
          url: '/wishlist/removeFrom.collections',
          send: [productId],
          type: 'DELETE',
          requestHeader: {
            header: 'Content-Type',
            value: 'application/json'
          },
          success: res,
          error: rej
        }));
      }
    };

    component.config = _config;
    component.init = _init;
    component.removeItemFromWishlist = _removeItemFromWishlist;
    component.init = _init;

    return component;
  };

  return wishlistProduct;
});
